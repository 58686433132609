// <!-- API -->
import { ref, computed } from 'vue';

// <!-- COMPOSABLES -->
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state';
import { ContentsPreviewState } from '~CSVUploader/hooks/form/useSelectProfileForm';

/**
 * Get the profile selection grid.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Object} payload
 * @param {V.Ref<UploadRecord>} payload.record
 */
// ts-ignore
export function useCSVPreviewGrid({ emit }, { record }) {
    /** Get grid API. */
    // ts-ignore
    const { defaultColDef, ...grid } = useAgGridVue();

    /** References. */
    const state = {
        /** @type {V.Ref<String[]>} */
        fields: ref([]),
        /** @type {V.Ref<AgGrid.ColumnDef>} */
        defaultColDef: ref(defaultColDef),
        domLayout: ref('autoHeight'),
        /** @type {V.Ref<AgGrid.ColumnDef[]>} */
        columnDefs: ref([]),
        rowSelection: ref('multiple'),
        rowData: ref([]),
        /** @type {V.Ref<GridApi>} */
        gridApi: ref(null),
        /** @type {V.Ref<ColumnApi>} */
        gridColumnApi: ref(null),
        rowHeight: ref(50),
    };

    const properties = {
        columnDefs: computed(() => {
            const fields = state.fields.value;
            /** @type {AgGrid.ColumnDef[]} */
            const defs = fields.map((def, index) => ({
                headerName: def,
                field: index === 0 ? 'index' : `${index - 1}`,
            }));
            return defs;
        }),
        clampedRowData: computed(() => {
            const data = [...state.rowData.value];
            if (data.length > 10) {
                return data.slice(0, 10);
            }
            return data;
        }),
    };

    // HOOKS
    /**
     * @param {ContentsPreviewState} preview
     * @param {Boolean} [includeHeaders]
     */
    const useContents = (preview, includeHeaders = false, offset = 0) => {
        const fields = [
            includeHeaders ? String(offset + 1) : 'Line Number',
            ...preview.getColumnLabels(includeHeaders, offset),
        ];
        const data = preview.getRowData(offset + (includeHeaders ? 1 : 0));
        useSchema(fields, data);
    };

    const useSchema = (fields, data) => {
        useFields(fields);
        useRowData(data);
    };

    const useFields = (fields) => {
        state.fields.value = fields ?? [
            'Column A',
            'Column B',
            'Column C',
            'Column D',
        ];
        state.gridApi.value?.sizeColumnsToFit();
    };

    const useRowData = (csv) => {
        state.rowData.value = csv ?? [
            {
                index: 0,
                0: 'A1',
                1: 'B1',
                2: 'C1',
                3: 'D1',
            },
            {
                index: 1,
                0: 'A2',
                1: 'B2',
                2: 'C2',
                3: 'D2',
            },
            {
                index: 2,
                0: 'A3',
                1: 'B3',
                2: 'C3',
                3: 'D3',
            },
            {
                index: 3,
                0: 'A4',
                1: 'B4',
                2: 'C4',
                3: 'D4',
            },
        ];
    };

    const useDomLayout = (layout) => {
        state.domLayout.value = layout;
    };
    /** @param {AgGrid.ColumnDef} defaultDefs */
    const useDefaultColDef = (defaultDefs) => {
        state.defaultColDef.value = defaultDefs;
    };

    const useColumnDefs = (colDefs) => {
        state.columnDefs.value = colDefs;
    };

    const useGridApi = (api) => {
        state.gridApi.value = api;
    };

    const useGridColumnApi = (api) => {
        state.gridColumnApi.value = api;
    };

    const useRowHeight = (height) => {
        state.rowHeight.value = height;
    };

    // GETTERS
    const getters = {
        getRowIdFromFileName: (data) => data.name,
    };

    // ACTIONS
    const actions = {
        updateColumnDefs: () => {
            useColumnDefs([]);
        },
        onGridReady: (event) => {
            state.gridApi.value = event.api;
            state.gridColumnApi.value = event.columnApi;
            state.gridColumnApi.value?.autoSizeAllColumns(false);
            state.gridApi.value?.sizeColumnsToFit();
        },
        onColumnResized: (event) => {
            // See: grid.onColumnResized(event);
            state.gridApi.value?.refreshCells();
            emit('resize:grid', event); // Currently unused.
        },
        // ts-ignore
        onRowDataChanged: (event) => {
            state.gridApi.value?.sizeColumnsToFit();
            state.gridApi.value?.refreshCells();
            state.gridColumnApi.value?.autoSizeAllColumns(false);
        },
        // ts-ignore
        onChangeRowData: (event) => {
            state.gridApi.value?.refreshCells();
        },
    };

    return {
        useGridApi,
        useGridColumnApi,
        useDefaultColDef,
        useColumnDefs,
        useFields,
        useSchema,
        useContents,
        useDomLayout,
        useRowHeight,
        useRowData,
        state,
        properties,
        getters,
        actions,
    };
}

export default useCSVPreviewGrid;
